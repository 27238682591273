
import {defineComponent, ref} from 'vue'
import {useAppStore} from "@/store/app/AppStore";
import {TYPE} from "vue-toastification";
import ClinicsReportsUploadModal from "@/components/clinics/reports/upload/fail/ClinicsReportsUploadModal.vue";
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";

export interface ReportCSVRow {
  readonly workerUID: string
  readonly imageUID: string
  readonly timestamp: number
  readonly count: number
}

export default defineComponent({
  name: "ClinicsReportsUpload",
  components: {ButtonAdd, ClinicsReportsUploadModal},
  setup() {

    const appStore = useAppStore()
    const reportRows = ref<ReportCSVRow[]>([])
    const addRowsModal = ref(false)

    const parseData = (data: string | undefined) => data?.split('\n').map(row => row.split(',').map(x => x.replace('\r', '')))
    const isValidHeader = (data: string[]) => data[0] === 'workerUID' && data[1] === 'imageUID' && data[2] === 'timestamp' && data[3] === 'count'


    const parseDate = (data: string): number | undefined => {
      const dateParts = data.split('.')
      if (dateParts.length !== 3) return
      const day = +dateParts[0]
      if (isNaN(day)) return
      const month = +dateParts[1]
      if (isNaN(month)) return
      const year = +dateParts[2]
      if (isNaN(year)) return;
      return new Date(year, month - 1, day).getTime()
    }
    const getRows = (data: string[][]): ReportCSVRow[] => data
        .filter(item => item[0]?.length && item[1]?.length && item[2]?.length && !!parseDate(item[2]) && +item[3] > 0)
        .map(item => ({
          workerUID: item[0] as string,
          imageUID: item[1] as string,
          timestamp: parseDate(item[2]) as number,
          count: +item[3]
        }))
    const setData = (data: string[][]) => {
      if (!isValidHeader(data[0])) {
        appStore.showToast(TYPE.ERROR, 'Vigane CSV päis')
        return
      }
      data.shift()
      reportRows.value = getRows(data)
      addRowsModal.value = true
    }


    const readCSV = (file: File) => {
      const reader = new FileReader()
      reader.addEventListener('load', (e) => {
        const parsedData = parseData(e.target?.result as string | undefined)
        if (parsedData) setData(parsedData)
      })
      reader.readAsBinaryString(file)
    }

    const onReportSelected = (e: { target: HTMLInputElement }) => {
      const files = e.target.files
      if (!files?.length) return
      const file = files[0]
      if (file.name.split('.')[1] !== 'csv') return
      readCSV(file)
    }
    return {onReportSelected, addRowsModal, reportRows}
  }
})
