
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watchEffect,
} from "vue";
import ClinicReportsList from "@/components/clinics/reports/ClinicReportsList.vue";
import {useSnapshotsStore} from "@/store/snapshots/SnapshotsStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";
import ClinicsReportsFilter, {
  ReportsFilterParams,
} from "@/components/clinics/reports/ClinicsReportsFilter.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import ClinicReportsHeader from "@/components/clinics/reports/ClinicReportsHeader.vue";
import ClinicsReportsControls from "@/components/clinics/reports/ClinicsReportsControls.vue";

import {PDFDataInput} from "@/shared/types";

import {useUserStore} from "@/store/user/UserStore";
import ReportsUpload from "@/components/clinics/reports/upload/ReportsUpload.vue";
import {PdfData} from "@/components/clinics/reports/pdfgenerator/pdf-parts/example-data";

export default defineComponent({
  components: {
    ReportsUpload,
    ClinicsReportsControls,
    ClinicReportsHeader,
    ClinicsReportsFilter,
    ClinicReportsList,
  },
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const isAdmin = computed(() => userState.admin);
    const snapshotsStore = useSnapshotsStore();
    const workersStore = useWorkersStore();
    const clinicsStore = useClinicsStore();
    const clinicsState = clinicsStore.getState();
    const activeClinicUID = computed(() => clinicsState.activeClinicUID);
    const activeClinic = computed(() =>
        activeClinicUID.value
            ? clinicsState.clinics.find((c) => c.uid === activeClinicUID.value)
            : undefined
    );

    const isDetailView = ref(false)
    const onDetailView = (state:boolean) => isDetailView.value = state

    onBeforeMount(
        async () =>
            await Promise.all([
              snapshotsStore.setSnapshots(true),
              workersStore.setWorkers(false),
              workersStore.setPastWorkers(false)
            ])
    );

    const clinicUID = ref<string | undefined>(undefined);
    const cabinetUID = ref<string | undefined>(undefined);
    const workerUID = ref<string | undefined>(undefined);
    const licenceNo = ref<string | undefined>(undefined);
    const dateFrom = ref<number | undefined>(undefined);
    const dateTo = ref<number | undefined>(undefined);
    const images = ref<string[] | undefined>(undefined)

    watchEffect(() => (clinicUID.value = activeClinicUID.value));

    const onFilter = (params: ReportsFilterParams) => {
      clinicUID.value = params.clinicUID;
      cabinetUID.value = params.cabinetUID;
      workerUID.value = params.workerUID;
      licenceNo.value = params.licenceNo;
      dateFrom.value = params.dateFrom;
      dateTo.value = params.dateTo;
      images.value = params.images
    };

    const filterParams = computed<ReportsFilterParams>(() => ({
      clinicUID: activeClinicUID.value,
      cabinetUID: cabinetUID.value,
      workerUID: workerUID.value,
      licenceNo: licenceNo.value,
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
      images: images.value
    }));

    const dateFromStr = computed(() => {
      if (!filterParams.value.dateFrom) return;
      const dateStr = new Date(filterParams.value.dateFrom);
      return `${dateStr.getDate()}.${
          dateStr.getMonth() + 1
      }.${dateStr.getFullYear()}`;
    });

    const dateToStr = computed(() => {
      if (!filterParams.value.dateTo) return;
      const dateStr = new Date(filterParams.value.dateTo);
      return `${dateStr.getDate()}.${
          dateStr.getMonth() + 1
      }.${dateStr.getFullYear()}`;
    });

    const title = computed(() => {
      const titleStart = "Kiirgustöötaja kutsekiirituse dooside aruanne";
      if (dateFrom.value && dateToStr.value)
        return `${titleStart} ${dateFromStr.value} - ${dateToStr.value}`;
      else if (dateFromStr.value && !dateToStr.value)
        return `${titleStart} alates ${dateFromStr.value}`;
      else if (!dateFromStr.value && dateToStr.value)
        return `${titleStart} kuni ${dateToStr.value}`;
      else return titleStart;
    });

    const subtitle = computed(() =>
        licenceNo.value ? `Kiirgustegevusluba ${licenceNo.value}` : ""
    );

    const workers = ref<PDFDataInput[]>([]);

    const tableData = computed(() =>
        workers.value.map((w, i) => ({
          number: `${i + 1}.`,
          licenseHolder: w.licenseHolder,
          radiationWorkerName: w.radiationWorkerName,
          idCode: w.idCode,
          annualExposure: w.annualExposure,
          category: w.category,
        }))
    );

    const pdfDataInput = computed<PdfData>(() => ({
      logo: activeClinic.value?.logo || "",
      reportYear: new Date().getFullYear(),
      licenseNumber: licenceNo.value || "",
      userName: activeClinic.value?.rapporteurName || "",
      companyName: activeClinic.value?.radiationPracticeLicenseHolder || "",
      companyEmail: activeClinic.value?.rapporteurEmail || "",
      date1: dateFromStr.value || "",
      date2: dateToStr.value || "",
      buildingName: activeClinic.value?.name || "",
      Address: activeClinic.value?.licenceLocation || "",
      measurementMethod: "Arvutuslik",
      tableData: tableData.value,
    }));

    const onWorkers = (items: PDFDataInput[]) => (workers.value = items);

    return {
      onFilter,
      filterParams,
      title,
      subtitle,
      pdfDataInput,
      onWorkers,
      isAdmin,
      onDetailView,
      isDetailView
    };
  },
});
